import { initializeApp } from "firebase/app";

// TODO: Replace the following with your app's Firebase project configuration

const firebaseConfig = {
  apiKey: "AIzaSyCrDxnVz4DVUlnyXGSKwtEJBaO6a5zo0Vc",
  authDomain: "aquadrop-7f434.firebaseapp.com",
  projectId: "aquadrop-7f434",
  storageBucket: "aquadrop-7f434.appspot.com",
  messagingSenderId: "92559930186",
  appId: "1:92559930186:web:3b87877da1f858ab7cc7b3",
  measurementId: "G-CPHVHBHEF6",
};

export const app = initializeApp(firebaseConfig);
