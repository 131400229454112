export default {
  signinWithGoogle: {
    width: "80%",
    textAlign: "center",
    height: "3rem",
    fontSize: "small",
    display: "flex",
    alignItems: "center",
    gap: "2rem",
  },
};
