import React from "react";
import Login from "../components/auth-comps/Login";

export default function LoginPage() {
  return (
    <div className="login-page">
      <Login />
    </div>
  );
}
