export default {
  center: {
    margin: "20px 0 0 0",
    padding: "0.7rem 1rem",
    border: "none",
    fontSize: "large",
    backgroundColor: "#0195FF",
    color: "white",
    borderRadius: "0.1rem",
    cursor: "pointer",
  },
};
